import { CustomModalCell } from '../../components/custom-modal-cell';

export const FORM_NAME = 'trailers-form';
export const CAR_FORM_NAME = 'user-role-form';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const TRAILER_TABLE_COLUMNS_NAMES = [
	{ id: 'title' },
	{ id: 'value', customComponent: CustomModalCell },
	{ id: 'moreInfo' },
];

export const TRAILER_TABLE_ROWS_NAMES = {
	number: 'Гос. номер',
	vin: 'Vin номер',
	type: 'Тип прицепа',
	issueDate: 'Год выпуска прицепа',
	refregIssueDate: 'Год выпуска рефрижератора',
	tonnage: 'Грузоподъемность',
	isAccredited: 'Прицеп аккредитован',
	author: 'Автор',
	temperatureMode: 'Температурный режим',
};

export const FIELDS_FOR_VALIDATE = ['name', 'number', 'vin', 'type_id', 'issue_date', 'tonnage_id'];

export const FORM_FIELDS = ['name', 'number', 'vin'];
