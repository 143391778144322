import { call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch } from 'redux-form';
import {
	AUTHORIZE_USER,
	setIdOfOpenModalAction,
	setIsForgotFormSubmittingAction,
	setIsUserAuthorizingAction,
	setLoginErrorMessageAction,
	SUBMIT_FORGOT_FORM,
} from './actions';
import { authorizeUserRequest, forgotPasswordRequest } from '../../api/requests';
import { notificationInit } from '../../modules/notifications/actions';
import {
	COMPANIES_TYPES_BY_ID,
	FORGOT_FORM_NAME,
	FORGOT_FORM_REQUIRED_FIELDS,
	LOGIN_FORM_NAME,
	LOGIN_FORM_REQUIRED_FIELDS,
	REQUEST_ERROR_MESSAGE,
} from './constants';
import { transformToValidationErrors } from './utils';
import {
	getForgotFormDataSelector,
	getForgotFormIsInvalidSelector,
	getLoginFormDataSelector,
	getLoginFormIsInvalidSelector,
} from './selectors';
import { deleteLocalStorageItem, getLocalStorageItem, setLocalStorageItem } from '../../utils/localStorage';
import { DISMISS_TIME } from '../../api/constants';
import { setUserDataAction } from '../right-header-content/actions';
import { getUserInfoAction, setUserRulesAction } from '../profile/actions';
import { errorHandler } from '../../api/utils';

export function* authorizeUserSaga({ payload: push }) {
	try {
		yield put(setIsUserAuthorizingAction(true));

		const isInvalid = yield select(getLoginFormIsInvalidSelector());

		const formData = yield select(getLoginFormDataSelector());

		if (isInvalid) return;

		const { data, message, toast, errors } = yield call(authorizeUserRequest, formData);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			setLocalStorageItem('user', data.access_token.token);
			setLocalStorageItem('rules', data.user.role.rules || {});
			yield put(setUserRulesAction(data.user.role.rules || {}));

			const userData = {
				last_name: data.user?.last_name || '-',
				first_name: data.user?.first_name || '-',
				email: data.user?.email || '-',
				company: data.user?.company?.name || '-',
				companyId: data.user?.company?.id || '-',
				isCompanyValidated: Boolean(data.user?.company?.is_validated),
				isCompanyAccredited: Boolean(data.user?.company?.is_accredited),
				email_verified_at: data.user?.email_verified_at,
				company_type: COMPANIES_TYPES_BY_ID[data.user?.company?.type?.id] || '',
			};

			setLocalStorageItem('user_data', userData);
			yield put(setUserDataAction(userData));
			yield put(getUserInfoAction());

			if (!userData.email_verified_at) {
				push('/email/verify');
			}

			if (userData.email_verified_at && !userData.isCompanyAccredited) {
				push('/company/accredited');
			}

			if (userData.email_verified_at && userData.isCompanyAccredited) {
				const url = getLocalStorageItem('last_url');
				if (url) {
					deleteLocalStorageItem('last_url');
					window.location = url;
				} else {
					push('/home/information');
				}
			}
		} else {
			if (errors) {
				yield put(touch(LOGIN_FORM_NAME, ...LOGIN_FORM_REQUIRED_FIELDS));
				yield put(stopSubmit(LOGIN_FORM_NAME, transformToValidationErrors(errors)));
			}
			if (message) {
				yield put(setLoginErrorMessageAction(message));
			}
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsUserAuthorizingAction(false));
	}
}
export function* forgotPasswordSaga() {
	try {
		yield put(setIsForgotFormSubmittingAction(true));

		const isInvalid = yield select(getForgotFormIsInvalidSelector());

		const formData = yield select(getForgotFormDataSelector());

		if (isInvalid) return;

		const { message, toast, errors } = yield call(forgotPasswordRequest, formData);

		if (toast) {
			yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));

			yield put(setIdOfOpenModalAction('login'));
		} else {
			if (errors) {
				yield put(touch(FORGOT_FORM_NAME, ...FORGOT_FORM_REQUIRED_FIELDS));
				yield put(stopSubmit(FORGOT_FORM_NAME, transformToValidationErrors(errors)));
			}
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsForgotFormSubmittingAction(false));
	}
}

export default function* loginSaga() {
	yield takeEvery(AUTHORIZE_USER, authorizeUserSaga);
	yield takeEvery(SUBMIT_FORGOT_FORM, forgotPasswordSaga);
}
