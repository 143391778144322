import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { reduxForm, reset, submit } from 'redux-form';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import {
	deleteTrailerAction,
	getTonnagesAction,
	getTrailerInfoAction,
	getTypesOfTrailersAction,
	resetStateAction,
	saveTrailerAction,
	updateTrailerAction,
	getTrailerTemperatureModesAction,
} from './actions';
import { FORM_NAME } from './constants';
import { TrailersModalView } from './components/trailers-modal-view';
import {
	getActiveTrailerIdSelector,
	getFormInitialValuesSelector,
	getIsAuditTabShownSelector,
	getIsModalShownSelector,
	getIsTonnagesLoadingSelector,
	getIsTrailerAddFormOpenSelector,
	getIsTrailerEditFormOpenSelector,
	getIsTrailerInfoLoadingSelector,
	getTonnagesSelector,
	getTrailerInfoSelector,
	getTrailerNameSelector,
	getTrailerTypesSelector,
	getTemperatureModesForTrailerSelector,
	getListOfTemperatureModesForTrailersSelector,
	getIsTrailerTypeAreValidForManagingTemperatureModesSelector,
} from './selectors';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { getIsAvailableTrailersButtonsSelector } from '../profile/selectors';
import { validate } from './utils';
import { setWindowInfoAction } from '../alert-modal/actions';

const TrailersModalWrapper = ({
	onGetTrailerInfo,
	isTrailerInfoLoading,
	isTrailerEditFormOpen,
	isTrailerAddFormOpen,
	trailerInfo,
	trailerName,
	onResetState,
	onDeleteTrailer,
	isModalShown,
	activeTrailerId,
	onSaveTrailer,
	onUpdateTrailer,
	onResetForm,
	isAvailable,
	onFormSubmit,
	onSetWindowInfo,
	isAuditTabShown,
	onGetTonnages,
	onGetTypesOfTrailers,
	tonnages,
	trailerTypes,
	isTonnagesLoading,
	trailerTemperatureModes,
	onGetTrailerTemperatureModes,
	listOfTemperatureModesForTrailers,
	isTrailerTypeAreValidForManagingTemperatureModes,
}) => {
	useInjectSaga({ key: 'trailersModalSaga', saga });
	useInjectReducer({ key: 'trailersModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		return () => onResetState();
	}, []);

	useEffect(() => {
		if (activeTrailerId) onGetTrailerInfo(activeTrailerId);
	}, [activeTrailerId]);

	useEffect(() => {
		if (isModalShown) {
			onGetTypesOfTrailers();
			onGetTonnages();
			onGetTrailerTemperatureModes();
		}
	}, [isModalShown]);

	const handleTrailerFormOpenButtonClick = mode => {
		if (activeTrailerId) {
			query.set('id', activeTrailerId);
		} else {
			query.delete('id');
		}

		if (mode === 'edit') {
			query.set('mode', 'edit');
		}

		if (mode === 'cancel') {
			query.delete('mode');
			onResetForm(FORM_NAME);
		}

		if (mode === 'cancel' && !activeTrailerId) {
			onResetState();
			query.delete('modal');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('tab');

		onResetState();
		history.push(`${pathname}?${query}`);
	};

	const handleModalGoBack = () => {
		onResetForm(FORM_NAME);

		if (query.get('mode')) {
			history.goBack();
		} else {
			handleModalClose();
		}
	};

	const handleNavItemClick = tab => {
		if (tab === 'info') {
			query.delete('tab');
		}

		if (tab === 'audit') {
			query.set('tab', 'audit');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleTrailerDelete = () => {
		onSetWindowInfo({
			type: 'delete',
			title: 'Вы уверены?',
			text: 'Вы не сможете это отменить',
			button: {
				type: 'success',
				text: 'Да, удалить',
				onClick: () => onDeleteTrailer({ id: activeTrailerId, redirect: handleModalClose }),
			},
		});
	};

	const handleTrailerFormSubmit = async () => {
		await onFormSubmit(FORM_NAME);

		if (activeTrailerId) {
			onUpdateTrailer({
				id: activeTrailerId,
				redirect: () => handleTrailerFormOpenButtonClick('cancel'),
				searchQuery: removeModalQuery(query.toString()),
			});
			return;
		}

		onSaveTrailer({
			redirect: handleModalGoBack,
			resetState: onResetState,
			searchQuery: removeModalQuery(query.toString()),
		});
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleTrailerFormSubmit();
		}
	};

	return (
		<TrailersModalView
			trailerInfo={trailerInfo}
			isTrailerInfoLoading={isTrailerInfoLoading}
			onTrailerFormSubmit={handleTrailerFormSubmit}
			isModalShown={isModalShown}
			onTrailerFormOpenButtonClick={handleTrailerFormOpenButtonClick}
			onModalClose={handleModalClose}
			onNavItemClick={handleNavItemClick}
			isTrailerEditFormOpen={isTrailerEditFormOpen}
			isTrailerAddFormOpen={isTrailerAddFormOpen}
			trailerName={trailerName}
			onTrailerDelete={handleTrailerDelete}
			omModalGoBack={handleModalGoBack}
			isAvailable={isAvailable}
			isAuditTabShown={isAuditTabShown}
			activeTrailerId={activeTrailerId}
			onKeyDown={handleKeyDown}
			tonnages={tonnages}
			trailerTypes={trailerTypes}
			listOfTemperatureModesForTrailers={listOfTemperatureModesForTrailers}
			isTonnagesLoading={isTonnagesLoading}
			trailerTemperatureModes={trailerTemperatureModes}
			isTrailerTypeAreValidForManagingTemperatureModes={isTrailerTypeAreValidForManagingTemperatureModes}
		/>
	);
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(TrailersModalWrapper);

const mapStateToProps = createStructuredSelector({
	trailerInfo: getTrailerInfoSelector(),
	isTrailerInfoLoading: getIsTrailerInfoLoadingSelector(),
	isTrailerAddFormOpen: getIsTrailerAddFormOpenSelector(),
	isTrailerEditFormOpen: getIsTrailerEditFormOpenSelector(),
	trailerName: getTrailerNameSelector(),
	isModalShown: getIsModalShownSelector(),
	activeTrailerId: getActiveTrailerIdSelector(),
	initialValues: getFormInitialValuesSelector(),
	isAvailable: getIsAvailableTrailersButtonsSelector(),
	isAuditTabShown: getIsAuditTabShownSelector(),
	tonnages: getTonnagesSelector(),
	trailerTypes: getTrailerTypesSelector(),
	isTonnagesLoading: getIsTonnagesLoadingSelector(),
	trailerTemperatureModes: getTemperatureModesForTrailerSelector(),
	listOfTemperatureModesForTrailers: getListOfTemperatureModesForTrailersSelector(),
	isTrailerTypeAreValidForManagingTemperatureModes: getIsTrailerTypeAreValidForManagingTemperatureModesSelector(),
});

const mapDispatchToProps = {
	onGetTrailerInfo: getTrailerInfoAction,
	onDeleteTrailer: deleteTrailerAction,
	onResetState: resetStateAction,
	onSaveTrailer: saveTrailerAction,
	onUpdateTrailer: updateTrailerAction,
	onResetForm: reset,
	onFormSubmit: submit,
	onSetWindowInfo: setWindowInfoAction,
	onGetTonnages: getTonnagesAction,
	onGetTypesOfTrailers: getTypesOfTrailersAction,
	onGetTrailerTemperatureModes: getTrailerTemperatureModesAction,
};

const TrailersModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

TrailersModalWrapper.propTypes = {
	onGetTrailerInfo: PropTypes.func.isRequired,
	trailerInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)]).isRequired,
		}),
	),
	isTrailerInfoLoading: PropTypes.bool.isRequired,
	isTrailerEditFormOpen: PropTypes.bool.isRequired,
	isTrailerAddFormOpen: PropTypes.bool.isRequired,
	trailerName: PropTypes.string.isRequired,
	onDeleteTrailer: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	activeTrailerId: PropTypes.string,
	onSaveTrailer: PropTypes.func.isRequired,
	onUpdateTrailer: PropTypes.func.isRequired,
	onResetForm: PropTypes.func.isRequired,
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	onFormSubmit: PropTypes.func.isRequired,
	onSetWindowInfo: PropTypes.func.isRequired,
	onGetTrailerTemperatureModes: PropTypes.func.isRequired,
	isAuditTabShown: PropTypes.bool.isRequired,
	onGetTonnages: PropTypes.func.isRequired,
	onGetTypesOfTrailers: PropTypes.func.isRequired,
	isTonnagesLoading: PropTypes.bool.isRequired,
	tonnages: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	trailerTypes: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	trailerTemperatureModes: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.shape({
				date: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
				validation: PropTypes.bool.isRequired,
			}),
		}),
	).isRequired,
	listOfTemperatureModesForTrailers: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	isTrailerTypeAreValidForManagingTemperatureModes: PropTypes.bool.isRequired,
};

export default TrailersModal;
