import { CustomCell } from '../../components/custom-cell';

export const ADMIN_TABLE_COLUMNS = [
	{ name: '#', id: 'id', width: '113px', minWidth: '113px', customComponent: CustomCell },
	{ name: 'Заказчик', id: 'shipper', width: '390px', customComponent: CustomCell },
	{ name: 'Перевозчик', id: 'carrier', width: '260px' },
	{ name: 'Договор', id: 'contract', width: '260px' },
	{ name: 'Условия', id: 'conditions', width: '260px' },
];

export const TABLE_COLUMNS = [
	{ name: '#', id: 'id', width: '113px', minWidth: '113px', customComponent: CustomCell },
	{ name: 'Заказчик', id: 'shipper', width: '390px', customComponent: CustomCell },
	{ name: 'Перевозчик', id: 'carrier', width: '260px' },
	{ name: 'Договор', id: 'contract', width: '260px' },
	{ name: '', id: '', width: '260px' },
];

export const NAV_PAGE_LIST = [{ text: 'Мои взаимоотношения', href: '/companies_relationship' }];

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const ADMIN_ROLE_NUMBER = 1;
