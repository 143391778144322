import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import './styles.less';
import { Field, FieldArray } from 'redux-form';
import { FormInputField } from '../../../../semantic-ui/components/form-input-field';
import { FormSelectField } from '../../../../semantic-ui/components/form-select-field';
import { TemperatureFieldArray } from '../trailer-temperature-modes-list';
import { FormCheckboxField } from '../../../../semantic-ui/components/form-checkbox-field';
import { CustomDatePicker } from '../../../../semantic-ui/components/custom-datepicker';

export const TrailerForm = ({
	isTrailerInfoLoading,
	tonnages,
	isTonnagesLoading,
	trailerTypes,
	trailerTemperatureModes,
	listOfTemperatureModesForTrailers,
	isTrailerTypeAreValidForManagingTemperatureModes,
}) => {
	return (
		<Grid className="trailer-form">
			<Grid.Row className="trailer-form__row">
				<Grid.Column width={5} className="trailer-form__column">
					Модель
				</Grid.Column>
				<Grid.Column width={9} className="trailer-form__column">
					<Field
						name="name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите модель"
						loading={isTrailerInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="trailer-form__row">
				<Grid.Column width={5} className="trailer-form__column">
					Гос. номер
				</Grid.Column>
				<Grid.Column width={9} className="trailer-form__column">
					<Field
						name="number"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите гос. номер"
						loading={isTrailerInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="trailer-form__row">
				<Grid.Column width={5} className="trailer-form__column">
					Vin номер
				</Grid.Column>
				<Grid.Column width={9} className="trailer-form__column">
					<Field
						name="vin"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите vin номер"
						loading={isTrailerInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="trailer-form__row">
				<Grid.Column width={5} className="trailer-form__column">
					Тип прицепа
				</Grid.Column>
				<Grid.Column width={7} className="trailer-form__column_with-select">
					<Field
						name="type_id"
						type="text"
						component={FormSelectField}
						autoComplete="off"
						options={trailerTypes}
						search
						dropdownClassName="static"
						noResultsMessage="Не найдено"
						placeholder="Выберите тип прицепа"
						isWithoutSelectOnBlur
					/>
				</Grid.Column>
			</Grid.Row>

			<Grid.Row className="trailer-form__row">
				<Grid.Column width={5} className="trailer-form__column">
					Год выпуска прицепа
				</Grid.Column>
				<Grid.Column width={3} className="trailer-form__column">
					<Field
						name="date_issue"
						type="text"
						component={CustomDatePicker}
						autoComplete="off"
						placeholder=". . / . . / . ."
						dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
					/>
				</Grid.Column>
			</Grid.Row>
			{isTrailerTypeAreValidForManagingTemperatureModes && (
				<Grid.Row className="trailer-form__row">
					<Grid.Column width={5} className="trailer-form__column">
						Год выпуска рефрижератора
					</Grid.Column>
					<Grid.Column width={3} className="trailer-form__column">
						<Field
							name="type_date_issue"
							type="text"
							component={CustomDatePicker}
							autoComplete="off"
							placeholder=". . / . . / . ."
							dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
						/>
					</Grid.Column>
				</Grid.Row>
			)}
			<Grid.Row className="trailer-form__row">
				<Grid.Column width={5} className="trailer-form__column">
					Грузоподъемность
				</Grid.Column>
				<Grid.Column width={3} className="trailer-form__column_with-select">
					<Field
						name="tonnage_id"
						type="text"
						component={FormSelectField}
						autoComplete="off"
						options={tonnages}
						dropdownClassName="static"
						loading={isTonnagesLoading || isTrailerInfoLoading}
						disabled={isTonnagesLoading || isTrailerInfoLoading}
						noResultsMessage="Не найдено"
						placeholder="°C"
						search
						isWithoutSelectOnBlur
					/>
				</Grid.Column>
			</Grid.Row>
			{isTrailerTypeAreValidForManagingTemperatureModes && (
				<FieldArray
					name="modes"
					component={TemperatureFieldArray}
					listOfTemperatureModesForTrailers={listOfTemperatureModesForTrailers}
					initialValues={trailerTemperatureModes}
				/>
			)}
			<Grid.Row className="trailer-form__row">
				<Grid.Column width={5} className="trailer-form__column">
					Прицеп аккредитован
				</Grid.Column>
				<Grid.Column width={3} className="trailer-form__column_with-select">
					<Field
						name="is_accredited"
						component={FormCheckboxField}
						autoComplete="off"
						placeholder="Прицеп аккредитован"
						toggle
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

TrailerForm.propTypes = {
	isTrailerInfoLoading: PropTypes.bool.isRequired,
	isTonnagesLoading: PropTypes.bool.isRequired,
	tonnages: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	trailerTypes: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	trailerTemperatureModes: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			value: PropTypes.shape({
				date: PropTypes.string.isRequired,
				name: PropTypes.string.isRequired,
				validation: PropTypes.bool.isRequired,
			}),
		}),
	).isRequired,
	listOfTemperatureModesForTrailers: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	isTrailerTypeAreValidForManagingTemperatureModes: PropTypes.bool.isRequired,
};
