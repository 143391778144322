export const GET_TRAILER_INFO = 'trailers-modal/GET_TRAILER_INFO';
export const getTrailerInfoAction = id => ({
	type: GET_TRAILER_INFO,
	id,
});

export const SET_TRAILER_INFO = 'trailers-modal/SET_TRAILER_INFO';
export const setTrailerInfoAction = payload => ({
	type: SET_TRAILER_INFO,
	payload,
});

export const GET_TYPES_OF_TRAILERS = 'trailers-modal/GET_TYPES_OF_TRAILERS';
export const getTypesOfTrailersAction = payload => ({
	type: GET_TYPES_OF_TRAILERS,
	payload,
});

export const SET_IS_TRAILER_TEMPERATURE_MODES_LOADING = 'trailers-modal/SET_IS_TRAILER_TEMPERATURE_MODES_LOADING';
export const setIsTrailerTemperatureModesLoading = payload => ({
	type: SET_IS_TRAILER_TEMPERATURE_MODES_LOADING,
	payload,
});

export const GET_TRAILER_TEMPERATURE_MODES = 'trailers-modal/GET_TRAILER_TEMPERATURE_MODES';
export const getTrailerTemperatureModesAction = payload => ({
	type: GET_TRAILER_TEMPERATURE_MODES,
	payload,
});

export const SET_TRAILER_TEMPERATURE_MODES = 'trailers-modal/SET_TRAILER_TEMPERATURE_MODES';
export const setTrailerTemperatureModesAction = payload => ({
	type: SET_TRAILER_TEMPERATURE_MODES,
	payload,
});

export const SET_LIST_OF_TEMPARATURE_MODES_FOR_TRAILERS = 'trailers-modal/SET_LIST_OF_TEMPARATURE_MODES_FOR_TRAILERS';
export const setListOfTemperatureModesForTrailersAction = payload => ({
	type: SET_LIST_OF_TEMPARATURE_MODES_FOR_TRAILERS,
	payload,
});

export const SET_TRAILER_TYPES = 'trailers-modal/SET_TRAILER_TYPES';
export const setTypesOfTrailersAction = payload => ({
	type: SET_TRAILER_TYPES,
	payload,
});

export const SET_IS_TRAILER_TYPES_LOADING = 'trailers-modal/SET_IS_TRAILER_TYPES_LOADING';
export const setIsTrailerTypesLoadingAction = payload => ({
	type: SET_IS_TRAILER_TYPES_LOADING,
	payload,
});

export const SET_IS_TRAILER_INFO_LOADING = 'trailers-modal/SET_IS_TRAILER_INFO_LOADING';
export const setIsTrailerInfoLoadingAction = payload => ({
	type: SET_IS_TRAILER_INFO_LOADING,
	payload,
});

export const DELETE_TRAILER = 'trailers-modal/DELETE_TRAILER';
export const deleteTrailerAction = payload => ({
	type: DELETE_TRAILER,
	payload,
});

export const SAVE_TRAILER = 'trailers-modal/SAVE_TRAILER';
export const saveTrailerAction = payload => ({
	type: SAVE_TRAILER,
	payload,
});

export const UPDATE_TRAILER = 'trailers-modal/UPDATE_TRAILER';
export const updateTrailerAction = payload => ({
	type: UPDATE_TRAILER,
	payload,
});

export const SET_IS_TRAILER_SAVING = 'trailers-modal/SET_IS_TRAILER_SAVING';
export const setIsTrailerSavingAction = payload => ({
	type: SET_IS_TRAILER_SAVING,
	payload,
});

export const SET_IS_TRAILER_DELETING = 'trailers-modal/SET_IS_TRAILER_DELETING';
export const setIsTrailerDeletingAction = payload => ({
	type: SET_IS_TRAILER_DELETING,
	payload,
});

export const RESET_STATE = 'trailers-modal/RESET_STATE';
export const resetStateAction = () => ({
	type: RESET_STATE,
});

export const GET_TONNAGES = 'trailers-modal/GET_TONNAGES';
export const getTonnagesAction = id => ({
	type: GET_TONNAGES,
	id,
});

export const SET_TONNAGES = 'trailers-modal/SET_TONNAGES';
export const setTonnagesAction = payload => ({
	type: SET_TONNAGES,
	payload,
});

export const SET_IS_TONNAGES_LOADING = 'trailers-modal/SET_IS_TONNAGES_LOADING';
export const setIsTonnagesLoadingAction = payload => ({
	type: SET_IS_TONNAGES_LOADING,
	payload,
});
