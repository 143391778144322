/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import moment from 'moment';
import { initialState } from './reducer';
import { ADMIN_ROLE_NUMBER, ADMIN_TABLE_ROWS_NAMES, DATE_FORMAT, FORM_NAME, TABLE_ROWS_NAMES } from './constants';
import { getStringFromField } from './utils';
import { getLocalStorageItem } from '../../utils/localStorage';

export const selectRelationshipModalDomain = state => state.relationshipModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};
export const selectProfileModalDomain = state => state.profileModalReducer || {};

export const getIsAdminSelector = () =>
	createSelector(selectProfileModalDomain, ({ userInfo }) => userInfo?.company?.id === ADMIN_ROLE_NUMBER);

export const getCompanyIdSelector = () =>
	createSelector(selectProfileModalDomain, ({ userInfo }) => userInfo?.company?.id);

export const getCurrentCompanySelector = () =>
	createSelector(selectProfileModalDomain, ({ userInfo }) => {
		const { company, companyId } = getLocalStorageItem('user_data');

		return userInfo?.company || { name: company, id: companyId };
	});

export const getRelationshipInfoSelector = () =>
	createSelector(selectRelationshipModalDomain, getIsAdminSelector(), ({ relationshipInfo }, isAdmin) => {
		if (!relationshipInfo) {
			return [];
		}

		const data = {
			shipper: relationshipInfo.from?.name || '-',
			carrier: relationshipInfo.to?.name || '-',
			contract: relationshipInfo.contract || '-',
			...(isAdmin
				? {
						insurance: getStringFromField(relationshipInfo.insurance),
						fee: getStringFromField(relationshipInfo.fee),
				  }
				: {}),
		};

		const rowsNames = isAdmin ? ADMIN_TABLE_ROWS_NAMES : TABLE_ROWS_NAMES;

		return Object.entries(data).reduce((result, [key, value]) => {
			return [...result, { id: key, title: rowsNames[key], value }];
		}, []);
	});

export const getRelationshipNameSelector = () =>
	createSelector(selectRelationshipModalDomain, ({ relationshipInfo }) => {
		return relationshipInfo?.id && relationshipInfo?.created_at
			? `Мои взаимоотношения №${relationshipInfo.id} от ${moment(relationshipInfo.created_at).format(
					DATE_FORMAT,
			  )}`
			: '-';
	});

export const getActiveRelationshipIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return query?.modal === 'relationship' ? query?.id : null;
	});

export const getIsOptionsListLoadingSelector = () =>
	createSelector(selectRelationshipModalDomain, ({ isCarriersListLoading, isShippersListLoading }) => ({
		shippers: isShippersListLoading,
		carriers: isCarriersListLoading,
	}));

export const getOptionsListSelector = () =>
	createSelector(selectRelationshipModalDomain, ({ shippersList, carriersList }) => ({
		shippers: shippersList,
		carriers: carriersList,
	}));

export const getFormInitialValuesSelector = () =>
	createSelector(
		selectRelationshipModalDomain,
		getIsAdminSelector(),
		getCompanyIdSelector(),
		({ relationshipInfo }, isAdmin, companyId) => {
			const shipperID = !isAdmin ? companyId : '';

			if (!relationshipInfo) {
				return { shipper: shipperID || '', carrier: '' };
			}

			return {
				shipper: relationshipInfo.from?.id || shipperID || '',
				carrier: relationshipInfo.to?.id || '',
				contract_number: relationshipInfo.contract_number || '',
				contract_date: relationshipInfo.contract_date || '',
				insurance: relationshipInfo.insurance || '',
				fee: relationshipInfo.fee ?? '',
			};
		},
	);

export const getIsRelationshipInfoLoadingSelector = () =>
	createSelector(
		selectRelationshipModalDomain,
		({ isRelationshipInfoLoading, isRelationshipDeleting, isRelationshipSaving }) =>
			isRelationshipInfoLoading || isRelationshipDeleting || isRelationshipSaving,
	);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'relationship');
	});

export const getIsAuditTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'relationship' && query?.tab === 'audit');
	});

export const getIsRelationshipAddFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'relationship' && query?.mode === 'add');
	});

export const getIsRelationshipEditFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(
			!isEmpty(query) && query?.modal === 'relationship' && Boolean(query?.id) && query?.mode === 'edit',
		);
	});

export const getDataForCreateOrUpdateRelationshipSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => {
		const { shipper, carrier, ...othersFormValues } = formValues;

		return {
			...othersFormValues,
			from_id: shipper,
			to_id: carrier,
		};
	});
