/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import moment from 'moment';
import { initialState } from './reducer';
import { FORM_NAME, TRAILER_TABLE_ROWS_NAMES } from './constants';

export const selectTrailersModalDomain = state => state.trailersModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};

export const getTrailerInfoSelector = () =>
	createSelector(selectTrailersModalDomain, ({ trailerInfo }) => {
		if (!trailerInfo) {
			return [];
		}
		const issueDate = trailerInfo.date_issue && moment(trailerInfo.date_issue).format('MM.YYYY');

		const refregIssueDate = trailerInfo.type_date_issue && moment(trailerInfo.type_date_issue).format('MM.YYYY');

		const data = {
			number: trailerInfo.number || '-',
			vin: trailerInfo.vin || '-',
			type: trailerInfo.type.name,
			issueDate: issueDate || '',
			refregIssueDate: refregIssueDate || '',
			tonnage: trailerInfo.tonnage?.name ? `${trailerInfo.tonnage?.name}т.` : '-',
			isAccredited: trailerInfo.is_accredited ? 'Да' : '-',
		};

		let finalData = Object.entries(data).reduce((result, [key, value]) => {
			return [...result, { id: key, title: TRAILER_TABLE_ROWS_NAMES[key], value }];
		}, []);

		trailerInfo.modes.forEach((el, index) => {
			const validationDate = el.date && moment(el.date).format('DD.MM.YYYY');
			finalData = [
				...finalData,
				{
					id: `temperatureMode${index}`,
					title: `${TRAILER_TABLE_ROWS_NAMES.temperatureMode} № ${index + 1}`,
					value: {
						name: `${el.mode.name} °C`,
						validation: el.is_validated,
						date: validationDate || '',
					},
				},
			];
		});

		finalData = [
			...finalData,
			{
				id: 'author',
				title: TRAILER_TABLE_ROWS_NAMES.author,
				value: {
					name: `${trailerInfo.user?.last_name || '-'} ${trailerInfo.user?.first_name || '-'}`,
					role: trailerInfo.user?.role.name || '-',
				},
			},
		];

		return finalData;
	});

export const getIsTrailerTypeAreValidForManagingTemperatureModesSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => {
		return formValues?.type_id === '1' || formValues?.type_id === '4';
	});

export const getIsTrailerInfoLoadingSelector = () =>
	createSelector(
		selectTrailersModalDomain,
		({ isTrailerInfoLoading, isTrailerDeleting, isTrailerSaving }) =>
			isTrailerInfoLoading || isTrailerDeleting || isTrailerSaving,
	);

export const getTrailerNameSelector = () =>
	createSelector(selectTrailersModalDomain, ({ trailerInfo }) => trailerInfo?.name || '-');

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'trailer');
	});

export const getActiveTrailerIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => (query?.modal === 'trailer' ? query?.id : null));

export const getIsTrailerAddFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'trailer' && query?.mode === 'add');
	});

export const getIsTrailerEditFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'trailer' && Boolean(query?.id) && query?.mode === 'edit');
	});

export const getIsAuditTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'trailer' && query?.tab === 'audit');
	});

export const getFormInitialValuesSelector = () =>
	createSelector(selectTrailersModalDomain, ({ trailerInfo }) => {
		if (!trailerInfo) {
			return {
				tonnage_id: 'none',
			};
		}

		return {
			number: trailerInfo.number,
			vin: trailerInfo.vin,
			name: trailerInfo.name,
			tonnage_id: trailerInfo.tonnage?.id ? String(trailerInfo.tonnage?.id) : 'none',
			type_id: trailerInfo.type.id ? String(trailerInfo.type.id) : 'none',
			date_issue: trailerInfo.date_issue,
			type_date_issue: trailerInfo.type_date_issue,
			modes: trailerInfo.modes.map(mode => {
				return {
					...mode,
					mode_id: mode.mode.id,
					date: moment(mode.date).format('YYYY-MM-DD'),
				};
			}),
			is_accredited: trailerInfo.is_accredited,
		};
	});

export const getTemperatureModesForTrailerSelector = () =>
	createSelector(getTrailerInfoSelector(), data => {
		return data?.filter(el => el.id.includes('temperatureMode'));
	});

export const getListOfTemperatureModesForTrailersSelector = () =>
	createSelector(selectTrailersModalDomain, ({ listOfTemperatureModes }) => {
		return listOfTemperatureModes?.map(el => {
			return { key: el.id, text: el.name, value: el.id };
		});
	});

export const getDataForSaveTrailerSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => ({
		...formValues,
		tonnage_id: formValues.tonnage_id === 'none' ? null : formValues.tonnage_id,
	}));

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getTonnagesSelector = () => createSelector(selectTrailersModalDomain, ({ tonnages }) => tonnages);
export const getTrailerTypesSelector = () =>
	createSelector(selectTrailersModalDomain, ({ trailerTypes }) => trailerTypes);

export const getIsTonnagesLoadingSelector = () =>
	createSelector(selectTrailersModalDomain, ({ isTonnagesLoading }) => isTonnagesLoading);
