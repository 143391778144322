import { CustomModalCell } from '../../components/custom-modal-cell';
import { CustomCarCell } from './components/custom-car-cell';

export const FORM_NAME = 'cars-form';

export const REQUEST_ERROR_MESSAGE = 'Ошибка сервера';

export const CAR_TABLE_COLUMNS_NAMES = [
	{ id: 'title' },
	{ id: 'value', customComponent: CustomModalCell },
	{ id: 'mode', customComponent: CustomCarCell },
];

export const CAR_TABLE_ROWS_NAMES = {
	number: 'Гос. номер',
	vin: 'Vin номер',
	type: 'Тип машины',
	dateIssue: 'Год выпуска машины',
	typeDateIssue: 'Год выпуска рефрижератора',
	tonnage: 'Грузоподъемность',
	trailer: 'Прицеп по умолчанию',
	isAccredited: 'Авто аккредитовано',
	author: 'Автор',
};

export const FIELDS_FOR_VALIDATE = ['name', 'number', 'type_id', 'date_issue'];

export const FORM_FIELDS = [
	'name',
	'number',
	'vin',
	'type_id',
	'tonnage_id',
	'date_issue',
	'type_date_issue',
	'trailer_id',
	'modes',
];

export const TYPES_WITH_DATE_ISSUE_AND_MODES = ['2', '5'];

export const TYPES_WITH_TRAILER = ['1'];
