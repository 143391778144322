/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { change, getFormValues, reduxForm, reset, submit } from 'redux-form';
import PropTypes from 'prop-types';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { FORM_NAME } from './constants';
import ModalFooterButtons from '../../semantic-ui/components/modal-window/modal-footer-buttons';
import { ModalWindow } from '../../semantic-ui/components/modal-window';
import {
	getActiveUserRoleIdSelector,
	getUserRoleInfoSelector,
	getUserRoleNameSelector,
	getFormInitialValuesSelector,
	getIsUserRoleAddFormOpenSelector,
	getIsUserRoleEditFormOpenSelector,
	getIsUserRoleInfoLoadingSelector,
	getIsModalShownSelector,
	getTableColumnsSelector,
	getTableRowsSelector,
	getIsAuditTabShownSelector,
} from './selectors';
import {
	createUserRoleAction,
	deleteUserRoleAction,
	getRulesAction,
	getUserRoleInfoAction,
	resetStateAction,
	updateUserRoleAction,
} from './actions';
import { UserRoleContent } from './components/user-role-content';
import { removeModalQuery } from '../../utils/removeModalQuery';
import { getIsAvailableUsersRolesButtonsSelector } from '../profile/selectors';
import { validate } from './utils';
import { setWindowInfoAction } from '../alert-modal/actions';
import { Audit } from '../../components/audit';

const UsersRolesModalWrapper = ({
	onGetUserRoleInfo,
	isUserRoleInfoLoading,
	isUserRoleEditFormOpen,
	isUserRoleAddFormOpen,
	userRoleInfo,
	userRoleName,
	onResetState,
	onDeleteUserRole,
	isModalShown,
	activeUserRoleId,
	onCreateUserRole,
	onUpdateUserRole,
	onResetForm,
	tableColumns,
	onGetRules,
	tableRows,
	isAvailable,
	formValues,
	onFormChange,
	onFormSubmit,
	onSetWindowInfo,
	isAuditTabShown,
}) => {
	useInjectSaga({ key: 'usersRolesModalSaga', saga });
	useInjectReducer({ key: 'usersRolesModalReducer', reducer });

	const { search, pathname } = useLocation();

	const query = new URLSearchParams(search);

	const history = useHistory();

	useEffect(() => {
		if (activeUserRoleId) onGetUserRoleInfo(activeUserRoleId);
	}, [activeUserRoleId]);

	useEffect(() => {
		if (isModalShown) {
			onGetRules();
		}
	}, [isModalShown]);

	const handleUserRoleFormOpenButtonClick = mode => {
		if (activeUserRoleId) {
			query.set('id', activeUserRoleId);
		} else {
			query.delete('id');
		}

		if (mode === 'edit') {
			query.set('mode', 'edit');
		}

		if (mode === 'cancel') {
			onResetForm(FORM_NAME);
			query.delete('mode');
		}

		if (mode === 'cancel' && !activeUserRoleId) {
			query.delete('modal');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleModalClose = () => {
		query.delete('modal');
		query.delete('mode');
		query.delete('id');
		query.delete('tab');

		onResetState();
		history.push(`${pathname}?${query}`);
	};

	const handleNavItemClick = tab => {
		if (tab === 'info') {
			query.delete('tab');
		}

		if (tab === 'audit') {
			query.set('tab', 'audit');
		}

		history.push(`${pathname}?${query}`);
	};

	const handleUserRoleDelete = () => {
		onSetWindowInfo({
			type: 'delete',
			title: 'Вы уверены?',
			text: 'Вы не сможете это отменить',
			button: {
				type: 'success',
				text: 'Да, удалить',
				onClick: () => onDeleteUserRole({ id: activeUserRoleId, redirect: handleModalClose }),
			},
		});
	};

	const handleModalGoBack = () => {
		onResetForm(FORM_NAME);

		if (query.get('mode')) {
			history.goBack();
		} else {
			handleModalClose();
		}
	};

	const handleUserRoleFormSubmit = async () => {
		await onFormSubmit(FORM_NAME);

		if (activeUserRoleId) {
			onUpdateUserRole({
				id: activeUserRoleId,
				redirect: () => handleUserRoleFormOpenButtonClick('cancel'),
				searchQuery: removeModalQuery(query.toString()),
			});
			return;
		}

		onCreateUserRole({
			redirect: handleModalGoBack,
			resetState: onResetState,
			searchQuery: removeModalQuery(query.toString()),
		});
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && e.shiftKey === false) {
			handleUserRoleFormSubmit();
		}
	};

	const handleCheckboxChange = (e, key, action) => {
		if (action === 'access' && e === false) {
			onFormChange(FORM_NAME, `rules.${key}.access`, false);
			onFormChange(FORM_NAME, `rules.${key}.create`, false);
			onFormChange(FORM_NAME, `rules.${key}.update`, false);
			onFormChange(FORM_NAME, `rules.${key}.delete`, false);
			return;
		}

		if (!formValues?.rules?.[key]?.access && ['create', 'update', 'delete'].includes(action)) {
			onFormChange(FORM_NAME, `rules.${key}.${action}`, false);
			return;
		}

		onFormChange(FORM_NAME, `rules.${key}.${action}`, e);
	};

	return (
		<ModalWindow
			isModalShown={isModalShown}
			onKeyDown={handleKeyDown}
			headerText={
				isUserRoleEditFormOpen || isUserRoleAddFormOpen
					? `${isUserRoleEditFormOpen ? 'Редактор роли' : 'Новая роль'}`
					: userRoleName
			}
			navItems={
				isUserRoleAddFormOpen || isUserRoleEditFormOpen
					? []
					: [
							{
								id: 'info',
								text: 'Информация',
								onClick: () => handleNavItemClick('info'),
								isActive: !isAuditTabShown,
							},
							{
								id: 'audit',
								text: 'Изменения',
								onClick: () => handleNavItemClick('audit'),
								isActive: isAuditTabShown,
							},
					  ]
			}
			modalContent={
				!isAuditTabShown ? (
					<UserRoleContent
						userRoleInfo={userRoleInfo}
						isUserRoleInfoLoading={isUserRoleInfoLoading}
						isUserRoleFormOpen={isUserRoleEditFormOpen || isUserRoleAddFormOpen}
						tableColumns={tableColumns}
						tableRows={tableRows}
						onCheckboxChange={handleCheckboxChange}
						formValues={formValues}
					/>
				) : (
					<Audit entityName="users_roles" entityId={activeUserRoleId} />
				)
			}
			style={{ width: '895px' }}
			actionButtons={[{ type: 'close', onClick: handleModalClose, text: 'user_role' }]}
			footerButtons={
				<ModalFooterButtons
					leftButtons={
						!(isUserRoleEditFormOpen || isUserRoleAddFormOpen) && isAvailable.delete && !isAuditTabShown
							? [
									{
										onClick: handleUserRoleDelete,
										text: 'Удалить',
										color: 'warning',
										disabled: isUserRoleInfoLoading,
									},
							  ]
							: []
					}
					rightButtons={
						isUserRoleEditFormOpen || isUserRoleAddFormOpen
							? [
									{
										onClick: handleUserRoleFormSubmit,
										text: 'Сохранить',
										color: 'primary',
										disabled: isUserRoleInfoLoading,
									},
									{
										onClick: isUserRoleAddFormOpen
											? handleModalGoBack
											: () => handleUserRoleFormOpenButtonClick('cancel'),
										text: 'Отмена',
										color: 'secondary',
										disabled: isUserRoleInfoLoading,
									},
							  ]
							: [
									...(isAvailable.update && !isAuditTabShown
										? [
												{
													onClick: () => handleUserRoleFormOpenButtonClick('edit'),
													text: 'Изменить',
													color: 'primary',
													disabled: isUserRoleInfoLoading,
												},
										  ]
										: []),
									{
										onClick: handleModalClose,
										text: 'Закрыть',
										color: 'secondary',
									},
							  ]
					}
				/>
			}
		/>
	);
};

const withForm = reduxForm({
	form: FORM_NAME,
	enableReinitialize: true,
	onSubmit: validate,
})(UsersRolesModalWrapper);

const mapStateToProps = createStructuredSelector({
	userRoleInfo: getUserRoleInfoSelector(),
	isUserRoleInfoLoading: getIsUserRoleInfoLoadingSelector(),
	isUserRoleAddFormOpen: getIsUserRoleAddFormOpenSelector(),
	isUserRoleEditFormOpen: getIsUserRoleEditFormOpenSelector(),
	userRoleName: getUserRoleNameSelector(),
	isModalShown: getIsModalShownSelector(),
	activeUserRoleId: getActiveUserRoleIdSelector(),
	initialValues: getFormInitialValuesSelector(),
	tableColumns: getTableColumnsSelector(),
	tableRows: getTableRowsSelector(),
	isAvailable: getIsAvailableUsersRolesButtonsSelector(),
	formValues: getFormValues(FORM_NAME),
	isAuditTabShown: getIsAuditTabShownSelector(),
});

const mapDispatchToProps = {
	onGetUserRoleInfo: getUserRoleInfoAction,
	onDeleteUserRole: deleteUserRoleAction,
	onResetState: resetStateAction,
	onCreateUserRole: createUserRoleAction,
	onUpdateUserRole: updateUserRoleAction,
	onGetRules: getRulesAction,
	onResetForm: reset,
	onFormChange: change,
	onFormSubmit: submit,
	onSetWindowInfo: setWindowInfoAction,
};

const UsersRolesModal = connect(mapStateToProps, mapDispatchToProps)(withForm);

UsersRolesModalWrapper.propTypes = {
	onGetUserRoleInfo: PropTypes.func.isRequired,
	userRoleInfo: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			value: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
				PropTypes.number,
			]).isRequired,
		}),
	),
	isUserRoleInfoLoading: PropTypes.bool.isRequired,
	isUserRoleEditFormOpen: PropTypes.bool.isRequired,
	isUserRoleAddFormOpen: PropTypes.bool.isRequired,
	userRoleName: PropTypes.string.isRequired,
	onDeleteUserRole: PropTypes.func.isRequired,
	onResetState: PropTypes.func.isRequired,
	isModalShown: PropTypes.bool.isRequired,
	activeUserRoleId: PropTypes.string,
	onCreateUserRole: PropTypes.func.isRequired,
	onUpdateUserRole: PropTypes.func.isRequired,
	onResetForm: PropTypes.func.isRequired,
	onGetRules: PropTypes.func.isRequired,
	tableColumns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])))
		.isRequired,
	tableRows: PropTypes.objectOf(
		PropTypes.shape({ title: PropTypes.string.isRequired, actions: PropTypes.arrayOf(PropTypes.string) }),
	),
	isAvailable: PropTypes.objectOf(PropTypes.bool),
	formValues: PropTypes.object,
	onFormChange: PropTypes.func.isRequired,
	onFormSubmit: PropTypes.func.isRequired,
	onSetWindowInfo: PropTypes.func.isRequired,
	isAuditTabShown: PropTypes.bool.isRequired,
};

export default UsersRolesModal;
