import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { Field } from 'redux-form';
import './styles.less';
import { FormSelectField } from '../../../../semantic-ui/components/form-select-field';

export const CarChangeModalForm = ({ isCarsLoading, carsOptions, trailersOptions, onCarChange }) => {
	return (
		<div className="car-change-modal-form__wrapper">
			<Grid className="car-change-modal-form">
				<Grid.Row className="car-change-modal-form__row">
					<Grid.Column width={6} className="car-change-modal-form__column">
						Машина
					</Grid.Column>
					<Grid.Column width={7} className="car-change-modal-form__column">
						<div className="car-change-modal-form__column-with-button">
							<div className="car-change-modal-form__field-with-button">
								<Field
									name="car_id"
									type="text"
									component={FormSelectField}
									autoComplete="off"
									placeholder="Выберите машину"
									options={carsOptions}
									loading={isCarsLoading}
									noResultsMessage="Неизвестная машина"
									search
									dropdownClassName="static"
									onChange={onCarChange}
								/>
							</div>
						</div>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="car-change-modal-form__row">
					<Grid.Column width={6} className="car-change-modal-form__column">
						Прицеп
					</Grid.Column>
					<Grid.Column width={7} className="car-change-modal-form__column">
						<div className="car-change-modal-form__column-with-button">
							<div className="car-change-modal-form__field-with-button">
								<Field
									name="trailer_id"
									type="text"
									component={FormSelectField}
									autoComplete="off"
									placeholder="Выберите прицеп"
									options={trailersOptions}
									loading={isCarsLoading}
									noResultsMessage="Неизвестный прицеп"
									search
									dropdownClassName="static"
								/>
							</div>
						</div>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
};

CarChangeModalForm.propTypes = {
	isCarsLoading: PropTypes.bool.isRequired,
	carsOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.number.isRequired,
			value: PropTypes.number.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	trailersOptions: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
	onCarChange: PropTypes.func.isRequired,
};
