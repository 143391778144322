/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Fragment } from 'react';
import { Grid } from 'semantic-ui-react';
import './styles.less';
import { Field, FieldArray } from 'redux-form';
import PropTypes from 'prop-types';
import { FormInputField } from '../../../../../../semantic-ui/components/form-input-field';
import AddButton from '../../../../../../components/images/add-button.svg';
import { FormCheckboxField } from '../../../../../../semantic-ui/components/form-checkbox-field';
import { FormSelect } from '../../../../../../semantic-ui/components/form-select';
import { KgIcon, PieceIcon, VolumeIcon } from '../../../../../../components/text-icon';
import { CustomDatePicker } from '../../../../../../semantic-ui/components/custom-datepicker';
import { FormSelectField } from '../../../../../../semantic-ui/components/form-select-field';
import { normalizeNumber } from '../../../../../../utils/normalizeNumber';
import { normalizeInteger } from '../../../../../../utils/normalizeInteger';

const AddressArrayComponent = ({
	fields,
	onAddressSearchChange,
	onAddressChange,
	addressesOptions,
	addressesSearchValue,
	onAddressDelete,
	isRegular,
	isWayInfoLoading,
	isAddressesLoading,
}) => {
	return fields.map((name, index) => {
		const { date, days } = fields.get(index);

		const searchValue = addressesSearchValue?.[`${name}.address`] || '';

		return (
			<Fragment key={`${name}.address`}>
				<Grid.Row className="way-form__row">
					<Grid.Column width={6} className="way-form__column">
						Адрес доставки #{index + 1}
					</Grid.Column>
					<Grid.Column width={7} className="way-form__column">
						<Field
							name={`${name}.address`}
							type="select"
							component={FormSelect}
							style={{ width: '100%' }}
							autoComplete="off"
							placeholder="Укажите адрес доставки"
							icon="search"
							options={addressesOptions?.[`${name}.address`] || []}
							noResultsMessage="Неизвестный адрес"
							searchValue={searchValue}
							onSearchChange={event => onAddressSearchChange(event, `${name}.address`)}
							onItemChange={value => onAddressChange(value, `${name}.address`)}
							loading={isWayInfoLoading || isAddressesLoading[`${name}.address`]}
						/>
					</Grid.Column>
				</Grid.Row>
				{!isRegular ? (
					<Grid.Row className="way-form__row">
						<Grid.Column width={6} className="way-form__column">
							Дата доставки
						</Grid.Column>
						<Grid.Column width={5} className="way-form__column">
							<Field
								name={`${name}.date`}
								type="text"
								component={CustomDatePicker}
								autoComplete="off"
								placeholder="Укажите дату доставки"
								dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
								value={date}
								loading={isWayInfoLoading}
							/>
						</Grid.Column>
						<Grid.Column width={3} className="way-form__column ">
							{index !== 0 && (
								<div
									className="way-form__column_close"
									onClick={() => {
										onAddressDelete(`${name}.address`);
										fields.remove(index);
									}}
								/>
							)}
						</Grid.Column>
					</Grid.Row>
				) : (
					<Grid.Row className="way-form__row">
						<Grid.Column width={6} className="way-form__column">
							Кол. дней со дня выхода машины
						</Grid.Column>
						<Grid.Column width={4} className="way-form__column">
							<Field
								name={`${name}.days`}
								type="text"
								normalize={normalizeNumber}
								component={FormInputField}
								autoComplete="off"
								placeholder="Укажите кол. дней"
								value={days}
								loading={isWayInfoLoading}
							/>
						</Grid.Column>
						<Grid.Column width={3} className="way-form__column">
							{index !== 0 && (
								<div
									className="way-form__column_close"
									onClick={() => {
										onAddressDelete(`${name}.address`);
										fields.remove(index);
									}}
								/>
							)}
						</Grid.Column>
					</Grid.Row>
				)}
				{index === fields.length - 1 && (
					<div className="way-form__button-row">
						<div className="way-form__button-row-text">Добавить адрес доставки</div>
						<img src={AddButton} alt="add-button" onClick={() => fields.push({})} />
					</div>
				)}
			</Fragment>
		);
	});
};

export const WayFormView = ({
	addressesOptions,
	onAddressSearchChange,
	addressesSearchValue,
	onAddressChange,
	isRegular,
	periodsData,
	onPeriodsChange,
	isAddressesLoading,
	onAddressDelete,
	modes,
	isWayInfoLoading,
	onModeChange,
}) => {
	return (
		<Grid className="way-form">
			<Grid.Row className="way-form__row">
				<Grid.Column width={6} className="way-form__column">
					Название
				</Grid.Column>
				<Grid.Column width={7} className="way-form__column">
					<Field
						name="name"
						type="text"
						component={FormInputField}
						autoComplete="off"
						placeholder="Укажите название маршрута"
						loading={isWayInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="way-form__row">
				<Grid.Column width={6} className="way-form__column">
					Регулярный маршрут
				</Grid.Column>
				<Grid.Column width={7} className="way-form__column">
					<Field name="is_regular" component={FormCheckboxField} toggle type="checkbox" />
				</Grid.Column>
			</Grid.Row>
			{isRegular && (
				<Grid.Row className="way-form__row">
					<Grid.Column width={6} className="way-form__column">
						Периодичность маршрута
					</Grid.Column>
					<Grid.Column width={7} className="way-form__column">
						<Field
							name="period"
							type="text"
							component={FormSelectField}
							autoComplete="off"
							search
							placeholder="Укажите периодичность маршрута"
							noResultsMessage="Неизвеcтная переодичность"
							onItemChange={onPeriodsChange}
							options={periodsData}
							loading={isWayInfoLoading}
						/>
					</Grid.Column>
				</Grid.Row>
			)}
			<Grid.Row className="way-form__row">
				<Grid.Column width={6} className="way-form__column">
					Адрес отгрузки
				</Grid.Column>
				<Grid.Column width={7} className="way-form__column">
					<Field
						name="address_from"
						type="text"
						component={FormSelect}
						autoComplete="off"
						icon="search"
						search
						placeholder="Укажите адрес отгрузки"
						options={addressesOptions.address_from}
						noResultsMessage="Неизвестный адрес"
						onItemChange={value => onAddressChange(value, 'address_from')}
						searchValue={addressesSearchValue.address_from || ''}
						onSearchChange={value => onAddressSearchChange(value, 'address_from')}
						loading={isWayInfoLoading || isAddressesLoading.address_from}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="way-form__row">
				<Grid.Column width={6} className="way-form__column">
					Дата выхода машины
				</Grid.Column>
				<Grid.Column width={5} className="way-form__column">
					<Field
						name="date_from"
						type="text"
						component={CustomDatePicker}
						dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
						autoComplete="off"
						placeholder="Укажите дату выхода машины"
						loading={isWayInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<FieldArray
				name="address_to"
				component={AddressArrayComponent}
				props={{
					onAddressSearchChange,
					onAddressChange,
					addressesOptions,
					isAddressesLoading,
					addressesSearchValue,
					onAddressDelete,
					isRegular,
					isWayInfoLoading,
				}}
			/>

			<Grid.Row className="way-form__row">
				<Grid.Column width={6} className="way-form__column">
					Свободная масса
				</Grid.Column>
				<Grid.Column width={7} className="way-form__column">
					<Field
						name="weight"
						type="text"
						normalize={normalizeNumber}
						component={FormInputField}
						autoComplete="off"
						icon={KgIcon}
						placeholder="Укажите свободную массу"
						loading={isWayInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="way-form__row">
				<Grid.Column width={6} className="way-form__column">
					Свободный объем
				</Grid.Column>
				<Grid.Column width={7} className="way-form__column">
					<Field
						name="volume"
						type="text"
						normalize={normalizeNumber}
						component={FormInputField}
						autoComplete="off"
						icon={VolumeIcon}
						placeholder="Укажите свободный объем"
						loading={isWayInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="way-form__row">
				<Grid.Column width={6} className="way-form__column">
					Свободно мест
				</Grid.Column>
				<Grid.Column width={7} className="way-form__column">
					<Field
						name="place"
						type="text"
						normalize={normalizeInteger}
						component={FormInputField}
						autoComplete="off"
						icon={PieceIcon}
						placeholder="Укажите сколько свободно мест"
						loading={isWayInfoLoading}
					/>
				</Grid.Column>
			</Grid.Row>
			<Grid.Row className="way-form__row">
				<Grid.Column width={6} className="way-form__column">
					Температурный режим
				</Grid.Column>
				<Grid.Column name="mode_ids" width={8} className="way-form__column">
					<Field
						name="mode_ids"
						component={({ meta: { error } }) => {
							return (
								<>
									<div className="way-form__modes">
										{modes.map(mode => (
											<div className="way-form__mode" key={mode.name}>
												<Field
													name={`modes.${mode.id}`}
													component={FormCheckboxField}
													toggle
													type="checkbox"
													onChange={onModeChange}
												/>
												<span className="way-form__mode-text">{mode.name}</span>
											</div>
										))}
									</div>
									{error && <span className="way-form__mode-error">{error}</span>}
								</>
							);
						}}
					/>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

WayFormView.propTypes = {
	addressesOptions: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				key: PropTypes.string.isRequired,
				value: PropTypes.string.isRequired,
				text: PropTypes.string,
				data: PropTypes.shape({
					title: PropTypes.string.isRequired,
					address: PropTypes.string.isRequired,
					deleted: PropTypes.bool,
				}),
			}),
		),
	).isRequired,
	isAddressesLoading: PropTypes.objectOf(PropTypes.bool).isRequired,
	onAddressSearchChange: PropTypes.func.isRequired,
	addressesSearchValue: PropTypes.object.isRequired,
	onAddressChange: PropTypes.func.isRequired,
	isRegular: PropTypes.bool,
	periodsData: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			key: PropTypes.string.isRequired,
			id: PropTypes.number,
		}),
	),
	modes: PropTypes.array.isRequired,
	onPeriodsChange: PropTypes.func.isRequired,
	onAddressDelete: PropTypes.func.isRequired,
	isWayInfoLoading: PropTypes.bool.isRequired,
	onModeChange: PropTypes.func.isRequired,
};
