import { call, debounce, fork, put, select, takeEvery } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { stopSubmit, touch } from 'redux-form';
import {
	CREATE_USER,
	GET_COMPANIES,
	setCompaniesAction,
	setIsRegistrationFormOpenAction,
	setIsCompaniesLoadingAction,
	setIsUserCreatingAction,
} from './actions';
import { createUserRequest, getCompaniesBySearchRequest } from '../../api/requests';
import { notificationInit } from '../../modules/notifications/actions';
import { REGISTRATION_FORM_NAME, REQUEST_ERROR_MESSAGE, REQUIRED_FIELDS } from './constants';
import { transformToValidationErrors } from './utils';
import { getCompaniesSearchValueSelector, getFormDataSelector, getFormIsInvalidSelector } from './selectors';
import { setLocalStorageItem } from '../../utils/localStorage';
import { DISMISS_TIME } from '../../api/constants';
import { setUserDataAction } from '../right-header-content/actions';
import { setUserRulesAction } from '../profile/actions';
import { COMPANIES_TYPES_BY_ID } from '../login/constants';
import { errorHandler } from '../../api/utils';

export function* createUserSaga({ payload: redirect }) {
	try {
		yield put(setIsUserCreatingAction(true));

		const isInvalid = yield select(getFormIsInvalidSelector());

		const formData = yield select(getFormDataSelector());

		if (isInvalid) return;

		const { data, message, toast, errors } = yield call(createUserRequest, formData);

		if (data) {
			if (toast) {
				yield put(notificationInit({ id: uuidv4(), dismissAfter: DISMISS_TIME, ...toast }));
			}

			yield put(setIsRegistrationFormOpenAction(false));

			setLocalStorageItem('user', data.access_token.token);
			setLocalStorageItem('rules', data.user.role.rules || {});
			yield put(setUserRulesAction(data.user.role.rules || {}));

			const userData = {
				last_name: data.user?.last_name || '-',
				first_name: data.user?.first_name || '-',
				email: data.user?.email || '-',
				company: data.user?.company?.name || '-',
				companyId: data.user?.company?.id || '-',
				isCompanyValidated: Boolean(data.user?.company?.is_validated),
				isCompanyAccredited: Boolean(data.user?.company?.is_accredited),
				company_type: COMPANIES_TYPES_BY_ID[data.user?.company?.type?.id] || '',
			};

			setLocalStorageItem('user_data', userData);
			yield put(setUserDataAction(userData));
			if (!userData.email_verified_at) {
				redirect('/email/verify');
			}

			if (userData.email_verified_at && !userData.isCompanyAccredited) {
				redirect('/company/accredited');
			}
		} else {
			if (errors) {
				yield put(touch(REGISTRATION_FORM_NAME, ...REQUIRED_FIELDS));
				yield put(stopSubmit(REGISTRATION_FORM_NAME, transformToValidationErrors(errors)));
			}
			throw new Error(message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsUserCreatingAction(false));
	}
}

export function* getCompaniesSaga() {
	try {
		yield put(setIsCompaniesLoadingAction(true));

		const searchQuery = yield select(getCompaniesSearchValueSelector());

		const response = yield call(getCompaniesBySearchRequest, searchQuery);

		if (response?.suggestions) {
			yield put(setCompaniesAction(response.suggestions));
		} else {
			throw new Error(response?.message || REQUEST_ERROR_MESSAGE);
		}
	} catch (error) {
		yield fork(errorHandler, error);
	} finally {
		yield put(setIsCompaniesLoadingAction(false));
	}
}

export default function* registrationSaga() {
	yield takeEvery(CREATE_USER, createUserSaga);
	yield debounce(300, GET_COMPANIES, getCompaniesSaga);
}
