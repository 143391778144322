import HomePage from '../../containers/home';
import CompaniesDictionaryContainer from '../../containers/dictionaries/companies';
import UsersRolesContainer from '../../containers/users-roles';
import UsersContainer from '../../containers/users';
import ReqsCargoContainer from '../../containers/reqs-cargo';
import EmailVerifyContainer from '../../containers/email-verify';
import { CompanyValidate } from '../../containers/company-validate';
import { CompanyAccredited } from '../../containers/company-accredited';
import RelationshipContainer from '../../containers/relationship';

export const unprotectedPageList = {
	emailVerify: {
		id: 'email-verify-page',
		path: '/email/verify/:id/:hash',
		title: 'Home',
		component: EmailVerifyContainer,
		exact: false,
	},
	emailVerifyForm: {
		id: 'email-verify-form',
		path: '/email/verify',
		title: 'Home',
		component: EmailVerifyContainer,
		exact: false,
	},
	companyValidateForm: {
		id: 'company-validate-form',
		path: '/company/validate',
		title: 'Home',
		component: CompanyValidate,
		exact: false,
	},
	companyAccreditedForm: {
		id: 'company-accredited-form',
		path: '/company/accredited',
		title: 'Home',
		component: CompanyAccredited,
		exact: false,
	},
};

export const protectedPageList = {
	homePage: {
		id: 'home-page',
		path: '/home',
		title: 'Home',
		component: HomePage,
		exact: false,
	},
	reqsPage: {
		id: 'reqs-page',
		path: '/reqs',
		title: 'Reqs',
		component: ReqsCargoContainer,
		exact: false,
	},
	companiesDictionary: {
		id: 'companies-dictionary',
		path: '/companies',
		title: 'Companies',
		component: CompaniesDictionaryContainer,
		exact: false,
	},
	usersRoles: {
		id: 'users-roles',
		path: '/users_roles',
		title: 'UsersRoles',
		component: UsersRolesContainer,
		exact: false,
	},
	users: {
		id: 'users',
		path: '/users',
		title: 'Users',
		component: UsersContainer,
		exact: false,
	},
	relationship: {
		id: 'relationship',
		path: '/companies_relationship',
		title: 'Relationship',
		component: RelationshipContainer,
		exact: false,
	},
};
