/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Loader, Table } from 'semantic-ui-react';
import { ReqsCustomModalCell } from '../reqs-custom-modal-cell';

const ReqTableComponent = ({ isTableDataLoading, rows, columns, lastRowStatus, onOpenModal }) => {
	return (
		<div className="req-table">
			{isTableDataLoading ? (
				<Loader active size="big" />
			) : (
				<Table basic="very" className="req-table__table">
					<Table.Body>
						{!rows.length && (
							<Table.Row>
								<Table.Cell className="req-table__no-data">Данные не найдены</Table.Cell>
							</Table.Row>
						)}
						{rows?.map((row, index) => {
							if (row.group) {
								return (
									<Table.Row key={row.group} className="req-table__table-group-title">
										<Table.Cell colSpan="3">{row.group}</Table.Cell>
									</Table.Row>
								);
							}

							if (
								['cargo', 'product_invoice', 'flight', 'chosen_comment'].includes(row.id) ||
								row.id.includes('cargo')
							) {
								return (
									<Table.Row
										key={row.id}
										className={`req-table__table-row${
											lastRowStatus && index === rows.length - 1
												? ` req-table__table-row_${lastRowStatus}`
												: ''
										}`}
									>
										<Table.Cell className="req-table__table-cell" key={`${row.id}title`}>
											{row.title}
										</Table.Cell>
										<Table.Cell
											colSpan="2"
											key={`${row.id}value`}
											className="req-table__table-cell"
										>
											<ReqsCustomModalCell
												rowData={row}
												onOpenModal={onOpenModal}
												columnId="value"
											/>
										</Table.Cell>
									</Table.Row>
								);
							}

							if (row.id.includes('size')) {
								return (
									<Table.Row rowSpan="2" key={row.id} className="req-table__table-row">
										<Table.Cell className="req-table__table-cell" key={`${row.id}title`}>
											{row.title}
										</Table.Cell>
										<Table.Cell
											colSpan="2"
											key={`${row.id}value`}
											className="req-table__table-cell"
										>
											<ReqsCustomModalCell rowData={row} columnId="value" />
										</Table.Cell>
									</Table.Row>
								);
							}

							return (
								<Table.Row
									key={row.id}
									className={`req-table__table-row${
										lastRowStatus && row.id === 'bid'
											? ` req-table__table-row_${lastRowStatus}`
											: ''
									}`}
								>
									{columns.map(({ id, width, customComponent: CustomComponent, ...columnData }) => {
										if (row.id === 'cargo') {
											return null;
										}

										if (CustomComponent) {
											return (
												<Table.Cell
													key={id}
													className="req-table__table-cell"
													style={{ width }}
												>
													<CustomComponent
														rowData={row}
														columnId={id}
														columnData={columnData}
													/>
												</Table.Cell>
											);
										}

										return (
											<Table.Cell className="req-table__table-cell" key={id} style={{ width }}>
												{row[id]}
											</Table.Cell>
										);
									})}
								</Table.Row>
							);
						})}
					</Table.Body>
				</Table>
			)}
		</div>
	);
};

ReqTableComponent.propTypes = {
	onOpenModal: PropTypes.func,
	isTableDataLoading: PropTypes.bool.isRequired,
	columns: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			customComponent: PropTypes.elementType,
		}),
	),
	rows: PropTypes.arrayOf(
		PropTypes.objectOf(
			PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.number]),
		),
	),
	lastRowStatus: PropTypes.oneOf(['success', 'danger', '']),
};

export { ReqTableComponent };
