import React, { useEffect } from 'react';
import { Field } from 'redux-form';
import { Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { FormCheckboxField } from '../../../../semantic-ui/components/form-checkbox-field';
import { FormSelectField } from '../../../../semantic-ui/components/form-select-field';
import { CustomDatePicker } from '../../../../semantic-ui/components/custom-datepicker';

export const TemperatureFieldArray = ({ fields, listOfTemperatureModesForTrailers }) => {
	useEffect(() => {
		if (fields.length === 0) {
			fields.push();
		}
	}, []);

	const handleAddNewField = () => {
		if (fields.length < 8) {
			fields.push();
		}
	};

	return (
		<>
			{fields.map((mode, index) => {
				return (
					<>
						<Grid.Row key={mode} className="trailer-form__row">
							<Grid.Column width={5} className="trailer-form__column">
								{`Температурный режим № ${index + 1}`}
							</Grid.Column>
							<Grid.Column width={2} className="trailer-form__column_with-select">
								<Field
									name={`modes.${index}.mode_id`}
									type="text"
									component={FormSelectField}
									autoComplete="off"
									options={listOfTemperatureModesForTrailers}
									search
									dropdownClassName="static"
									noResultsMessage="Не найдено"
									placeholder="Выберите температурный режим"
									isWithoutSelectOnBlur
								/>
							</Grid.Column>
							<Grid.Column width={3} className="trailer-form__column">
								<div className="trailer-form__column_with_text">
									<large className="trailer-form__column_with_text-title">Валидация</large>
									<Field
										toggle
										name={`modes.${index}.is_validated`}
										component={FormCheckboxField}
										placeholder="Валидация"
									/>
								</div>
							</Grid.Column>
							<Grid.Column width={5} className="trailer-form__column">
								<div className="trailer-form__column_with_text">
									<large className="trailer-form__column_with_text-title">Действительна до</large>
									<Field
										name={`modes.${index}.date`}
										type="text"
										component={CustomDatePicker}
										autoComplete="off"
										placeholder=". . / . . / . ."
										dateFormat={['dd.MM.yyyy', 'dd-MM-yyyy', 'dd/MM/yyyy']}
									/>
								</div>
							</Grid.Column>
						</Grid.Row>
						{index === fields.length - 1 && (
							<>
								<Grid.Column width={10} className="trailer-form__column" />
								<Grid.Column width={6} className="trailer-form__column">
									<div className="trailer-form__column_add">
										<span>Добавить температурный режим</span>
										<span onClick={handleAddNewField} className="trailer-form__column_add-button" />
									</div>
								</Grid.Column>
							</>
						)}
					</>
				);
			})}
		</>
	);
};

TemperatureFieldArray.propTypes = {
	fields: PropTypes.any,
	listOfTemperatureModesForTrailers: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
		}),
	).isRequired,
};
